import { MouseEvent } from 'react'
import { getIsFirstTimeUser } from '@helpers/session/user'
import { analytics } from '@helpers/analytics'
import styles from './styles.module.scss'
import FeatureCardsDefault from './FeatureCardsDefault'
import { getValueFromObject } from '@helpers/utils'
import SectionHeadline from '../SectionHeadline'
import FeatureCardsFullBleed from './FeatureCardsFullBleed'
import { Cards, ContentFulPageProps } from './types'

function getPageData(props: ContentFulPageProps) {
  const cards: Cards = [
    {
      id: 0,
      title: props?.title1,
      event: {
        action: 'Stretch'
      },
      copy: props?.copy1?.content,
      backgroundColor: props.card1BackgroundColor,
      images: {
        desktopLarge: {
          url: getValueFromObject(
            props,
            'imageDesktopLarge1.file.url',
            undefined
          ),
          alt: getValueFromObject(props, 'imageDesktopLarge1.title', undefined),
          width: getValueFromObject(
            props,
            'imageDesktopLarge1.file.details.image.width',
            undefined
          ),
          height: getValueFromObject(
            props,
            'imageDesktopLarge1.file.details.image.height',
            undefined
          )
        },
        desktop: {
          url: getValueFromObject(props, 'imageDesktop1.file.url', undefined),
          alt: getValueFromObject(props, 'imageDesktop1.title', undefined),
          width: getValueFromObject(
            props,
            'imageDesktop1.file.details.image.width',
            undefined
          ),
          height: getValueFromObject(
            props,
            'imageDesktop1.file.details.image.height',
            undefined
          )
        },
        mobile: {
          url: getValueFromObject(props, 'imageMobile1.file.url', undefined),
          alt: getValueFromObject(props, 'imageMobile1.title', undefined),
          width: getValueFromObject(
            props,
            'imageMobile1.file.details.image.width',
            undefined
          ),
          height: getValueFromObject(
            props,
            'imageMobile1.file.details.image.height',
            undefined
          )
        }
      },
      thumbnail: {
        image: {
          url: getValueFromObject(props, 'thumbnail1.file.url', undefined),
          alt: getValueFromObject(props, 'thumbnail1.title', undefined),
          width: getValueFromObject(
            props,
            'thumbnail1.file.details.image.width',
            undefined
          ),
          height: getValueFromObject(
            props,
            'thumbnail1.file.details.image.height',
            undefined
          )
        },
        label: props?.thumbnailLabel1
      },
      visibility: getValueFromObject(props, 'visibility1', false),
      theme: getValueFromObject(props, 'theme1', 'dark-theme')
    },
    {
      id: 1,
      title: props?.title2,
      event: {
        action: 'Stretch'
      },
      copy: props?.copy2?.content,
      backgroundColor: props.card2BackgroundColor,
      images: {
        desktopLarge: {
          url: getValueFromObject(
            props,
            'imageDesktopLarge2.file.url',
            undefined
          ),
          alt: getValueFromObject(props, 'imageDesktopLarge2.title', undefined),
          width: getValueFromObject(
            props,
            'imageDesktopLarge2.file.details.image.width',
            undefined
          ),
          height: getValueFromObject(
            props,
            'imageDesktopLarge2.file.details.image.height',
            undefined
          )
        },
        desktop: {
          url: getValueFromObject(props, 'imageDesktop2.file.url', undefined),
          alt: getValueFromObject(props, 'imageDesktop2.title', undefined),
          width: getValueFromObject(
            props,
            'imageDesktop2.file.details.image.width',
            undefined
          ),
          height: getValueFromObject(
            props,
            'imageDesktop2.file.details.image.height',
            undefined
          )
        },
        mobile: {
          url: getValueFromObject(props, 'imageMobile2.file.url', undefined),
          alt: getValueFromObject(props, 'imageMobile2.title', undefined),
          width: getValueFromObject(
            props,
            'imageMobile2.file.details.image.width',
            undefined
          ),
          height: getValueFromObject(
            props,
            'imageMobile2.file.details.image.height',
            undefined
          )
        }
      },
      thumbnail: {
        image: {
          url: getValueFromObject(props, 'thumbnail2.file.url', undefined),
          alt: getValueFromObject(props, 'thumbnail2.title', undefined),
          width: getValueFromObject(
            props,
            'thumbnail2.file.details.image.width',
            undefined
          ),
          height: getValueFromObject(
            props,
            'thumbnail2.file.details.image.height',
            undefined
          )
        },
        label: props?.thumbnailLabel2
      },
      visibility: getValueFromObject(props, 'visibility2', false),
      theme: getValueFromObject(props, 'theme2', 'dark-theme')
    },
    {
      id: 2,
      title: props?.title3,
      event: {
        action: 'Stretch'
      },
      copy: props?.copy3?.content,
      backgroundColor: props.card3BackgroundColor,
      images: {
        desktopLarge: {
          url: getValueFromObject(
            props,
            'imageDesktopLarge3.file.url',
            undefined
          ),
          alt: getValueFromObject(props, 'imageDesktopLarge3.title', undefined),
          width: getValueFromObject(
            props,
            'imageDesktopLarge3.file.details.image.width',
            undefined
          ),
          height: getValueFromObject(
            props,
            'imageDesktopLarge3.file.details.image.height',
            undefined
          )
        },
        desktop: {
          url: getValueFromObject(props, 'imageDesktop3.file.url', undefined),
          alt: getValueFromObject(props, 'imageDesktop3.title', undefined),
          width: getValueFromObject(
            props,
            'imageDesktop3.file.details.image.width',
            undefined
          ),
          height: getValueFromObject(
            props,
            'imageDesktop3.file.details.image.height',
            undefined
          )
        },
        mobile: {
          url: getValueFromObject(props, 'imageMobile3.file.url', undefined),
          alt: getValueFromObject(props, 'imageMobile3.title', undefined),
          width: getValueFromObject(
            props,
            'imageMobile3.file.details.image.width',
            undefined
          ),
          height: getValueFromObject(
            props,
            'imageMobile3.file.details.image.height',
            undefined
          )
        }
      },
      thumbnail: {
        image: {
          url: getValueFromObject(props, 'thumbnail3.file.url', undefined),
          alt: getValueFromObject(props, 'thumbnail3.title', undefined),
          width: getValueFromObject(
            props,
            'thumbnail3.file.details.image.width',
            undefined
          ),
          height: getValueFromObject(
            props,
            'thumbnail3.file.details.image.height',
            undefined
          )
        },
        label: props?.thumbnailLabel3
      },
      visibility: getValueFromObject(props, 'visibility3', false),
      theme: getValueFromObject(props, 'theme3', 'dark-theme')
    }
  ].filter(card => card.visibility)

  return {
    title: props?.title,
    imagesFullBleed: props?.imagesFullBleed,
    backgroundColor: props?.backgroundColor,
    cards
  }
}

export default function FeatureCards(props: ContentFulPageProps) {
  const { cards, imagesFullBleed, backgroundColor, title } = getPageData(props)
  const isFullBleed = imagesFullBleed

  const handleCallOutClick = (
    ev: MouseEvent<HTMLButtonElement>,
    id: number
  ) => {
    ev.preventDefault()
    const firstTimeUser = getIsFirstTimeUser()

    const action = cards[id]?.event?.action
    analytics('Click', {
      category: 'Landing Page',
      label: 'Asset Breakdown',
      action,
      type: firstTimeUser ? 'new' : 'existing'
    })
  }

  const hasBackgroundColor =
    backgroundColor && backgroundColor !== '' && backgroundColor !== 'default'
  return (
    <>
      {title && <SectionHeadline title={title} showBorder={!isFullBleed} />}

      <section
        className={`${styles.FeatureCards} ${
          hasBackgroundColor ? styles.FeatureCards_withBackground : ''
        } ${isFullBleed ? styles.FeatureCards_fullBleed : ''}`}
      >
        {isFullBleed ? (
          <FeatureCardsFullBleed
            cards={cards}
            onThumbnailClick={handleCallOutClick}
          />
        ) : (
          <FeatureCardsDefault
            cards={cards}
            onThumbnailClick={handleCallOutClick}
          />
        )}
      </section>
    </>
  )
}
