import { useState } from 'react'
import SectionHeader from '@components/ui/SectionHeader'
import FeatureCardsTabs from '../shared/FeatureCardsTabs'
import FeatureCardsCopy from '../shared/FeatureCardsCopy'
import { Cards, OnThumbnailClick } from '../types'
import styles from './styles.module.scss'
import genericStyles from '../styles.module.scss'

type FeatureCardsDefaultProps = {
  cards: Cards
  onThumbnailClick: OnThumbnailClick
}
export default function FeatureCardsDefault({
  cards,
  onThumbnailClick
}: FeatureCardsDefaultProps) {
  const [selectedCard, setSelectedCard] = useState<number>(0)

  return (
    <div
      className={genericStyles.FeatureCards__wrapper}
      style={
        {
          '--text-color': '#001e3c',
          '--text-transition': 'color 0.2s cubic-bezier(0.4, 0, 0.6, 1)'
        } as React.CSSProperties
      }
    >
      <picture className={styles.FeatureCards__image}>
        <source
          media="(min-width: 700px)"
          srcSet={cards[selectedCard].images.desktop.url}
        />
        <img
          alt={cards[selectedCard].images.mobile.alt}
          src={
            cards[selectedCard].images.mobile.url ||
            cards[selectedCard].images.desktop.url
          }
        />
      </picture>

      <div
        className={`${styles.FeatureCards__content} ${
          cards.length === 1 ? styles.FeatureCards__contentVerticalCenter : ''
        }`}
      >
        <FeatureCardsTabs
          cards={cards}
          selectedCard={selectedCard}
          setSelectedCard={setSelectedCard}
          onThumbnailClick={onThumbnailClick}
          variant="default"
        />

        {cards[selectedCard].title && (
          <div className={genericStyles.FeatureCards__sectionTitle}>
            <SectionHeader
              title={cards[selectedCard].title}
              variant="left_aligned"
            />
          </div>
        )}

        <FeatureCardsCopy copy={cards[selectedCard].copy} />
      </div>
    </div>
  )
}
