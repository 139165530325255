import { useCallback, useState } from 'react'
import SectionHeader from '@components/ui/SectionHeader'
import FeatureCardsTabs from '../shared/FeatureCardsTabs'
import FeatureCardsCopy from '../shared/FeatureCardsCopy'
import useEmblaCarousel from 'embla-carousel-react'
import Fade from 'embla-carousel-fade'
import { Cards, OnThumbnailClick } from '../types'
import styles from './styles.module.scss'
import genericStyles from '../styles.module.scss'

type FeatureCardsFullBleedProps = {
  cards: Cards
  onThumbnailClick: OnThumbnailClick
}

export default function FeatureCardsFullBleed({
  cards,
  onThumbnailClick
}: FeatureCardsFullBleedProps) {
  const [selectedCard, setSelectedCard] = useState<number>(0)
  const [emblaMainRef, emblaMainApi] = useEmblaCarousel(
    { slidesToScroll: 1, loop: false },
    [Fade()]
  )
  const isSelectedCardLightTheme = cards[selectedCard].theme === 'light-theme'

  const onSelect = useCallback(
    (index: number) => {
      if (!emblaMainApi) return
      emblaMainApi.scrollTo(index)
      setSelectedCard(index)
    },
    [emblaMainApi]
  )

  return (
    <div
      className={styles.FeatureCards__fullBleedContaier}
      style={
        {
          '--text-color': isSelectedCardLightTheme ? '#FFFFFF' : '#001e3c',
          '--text-transition': 'color 0.2s cubic-bezier(0.4, 0, 0.6, 1)',
          backgroundColor: cards[selectedCard].backgroundColor
        } as React.CSSProperties
      }
      ref={emblaMainRef}
    >
      <div className={styles.FeatureCards__gallery}>
        {cards.map((card, index) => (
          <picture className={styles.FeatureCards__image} key={index}>
            {card.images.desktopLarge.url && (
              <source
                media="(min-width: 1800px)"
                srcSet={card.images.desktopLarge.url}
              />
            )}

            <source
              media="(min-width: 600px)"
              srcSet={card.images.desktop.url}
            />
            <img alt={card.images.mobile.alt} src={card.images.mobile.url} />
          </picture>
        ))}
      </div>

      <div
        className={`${genericStyles.FeatureCards__wrapper} ${styles.FeatureCards__wrapper}`}
      >
        <div></div>
        <div className={styles.FeatureCards__content}>
          <FeatureCardsTabs
            cards={cards}
            selectedCard={selectedCard}
            setSelectedCard={onSelect}
            onThumbnailClick={onThumbnailClick}
            variant="minimal"
          />

          {cards[selectedCard].title && (
            <div className={genericStyles.FeatureCards__sectionTitle}>
              <SectionHeader
                title={cards[selectedCard].title}
                variant="left_aligned"
                className={genericStyles.FeatureCards__sectionTitle}
              />
            </div>
          )}

          <FeatureCardsCopy copy={cards[selectedCard].copy} />
        </div>
      </div>
    </div>
  )
}
